import React, { FC, useMemo, PropsWithChildren } from "react";
import { useGoogleContext } from "@reasongcp/react-fire-sub";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import config from "../../../config";

/*
 * Initializes the client via a memoized hook so that we can use the current
 * user to inject Firebase auth credentials that are fresh into GQL requests.
 */
const useApolloClient = () => {
  const { googleJwt } = useGoogleContext();
  const uri = config.apolloUri;

  return useMemo(() => {
    const link = createHttpLink({ uri });

    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          "google-authorization": googleJwt,
        },
      };
    });

    return new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache(),
    });
  }, [googleJwt, uri]);
};

const ApolloAdminProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const client = useApolloClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAdminProvider;

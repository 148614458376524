import React, { FC } from "react";
import { Table, Card } from "react-bootstrap";
import styled from "styled-components";
import { FormPeriodWithFormType } from "../../../../types/formPeriods";
import FormPeriodRow from "./FormPeriodRow";

export interface FormPeriodProps {
  formPeriods?: FormPeriodWithFormType[];
}

const FormPeriodsTable: FC<Required<FormPeriodProps>> = ({ formPeriods }) => {
  return (
    <Table
      striped
      bordered
    >
      <thead>
        <tr>
          <th>ID</th>
          <th>Filing Year</th>
          <th>Short Name</th>
          <th>Long Name</th>
          <th>Start Time</th>
          <th>Filing Deadline</th>
          <th>Timezone</th>
        </tr>
      </thead>
      <tbody>
        {formPeriods.map((formPeriod) => (
          <FormPeriodRow
            key={formPeriod.id}
            formPeriod={formPeriod}
          />
        ))}
      </tbody>
    </Table>
  );
};

const StyledCard = styled(Card)`
  margin-top: 1rem;
`;

const FormPeriods: FC<FormPeriodProps> = ({ formPeriods }) => {
  if (!formPeriods?.length) return null;

  return (
    <StyledCard>
      <Card.Header>
        <h3>Form Periods</h3>
      </Card.Header>
      <Card.Body>
        <FormPeriodsTable formPeriods={formPeriods} />
      </Card.Body>
    </StyledCard>
  );
};

export default FormPeriods;

import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { Card, Col, Container, Row } from "react-bootstrap";
import JurisdictionSearch from "./JurisdictionSearch";
import JurisdictionFilters from "./JurisdictionFilters";

const StyledContainer = styled(Container)`
  padding: 1rem;
`;

interface TableWrapperProps {
  searchTerm: string;
  setSearchTerm: (val: string) => void;
}

const TableWrapper: FC<PropsWithChildren<TableWrapperProps>> = ({
  children,
  searchTerm,
  setSearchTerm,
}) => {
  return (
    <StyledContainer>
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <JurisdictionSearch
                value={searchTerm}
                onChange={setSearchTerm}
              />
            </Col>
            <Col md={6}>
              <JurisdictionFilters />
            </Col>
          </Row>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Card.Body>
      </Card>
    </StyledContainer>
  );
};

export default TableWrapper;

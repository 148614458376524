import React, { ChangeEvent, FC, useCallback } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

interface JurisdictionSearchProps {
  value?: string;
  onChange: (val: string) => void;
}

const StyledControl = styled(Form.Control)`
  height: 38px;
`;

const StyledLabel = styled(Form.Label)`
  margin-bottom: 0;
`;

const JurisdictionSearch: FC<JurisdictionSearchProps> = ({
  onChange,
  value = "",
}) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);
  return (
    <>
      <Form.Group>
        <StyledLabel>Search By Name</StyledLabel>
        <StyledControl
          type="text"
          value={value}
          onChange={handleChange}
          placeholder="Search by name"
        />
      </Form.Group>
    </>
  );
};

export default JurisdictionSearch;

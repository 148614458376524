import { useField } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface FormDatePickerProps {
  label: string;
  fieldName: string;
}

const FormDatePicker: FC<FormDatePickerProps> = ({
  label,
  fieldName,
}) => {
  // eslint-disable-next-line
  const [field, _, fieldHelpers] = useField(fieldName);
  const dateValue = useMemo(() => {
    const value = field.value;
    if (!value) return new Date();
    if (value instanceof Date) return value;
    if (!isNaN(Number(value))) {
      return new Date(Number(value));
    }
    return new Date(value);
  }, [field]);

  // We know this is a date object, but Form.Control doesn't...
  const handleChange = useCallback((val: any) => {
    fieldHelpers.setValue(val.toDateString());
  }, [fieldHelpers]);

  return (
    <PickerWrapper>
      <Form.Label>
        {label}
      </Form.Label>

      <Form.Control
        as={ReactDatePicker}
        selected={dateValue}
        onChange={handleChange}
      />
    </PickerWrapper>
  );
};

export default FormDatePicker;

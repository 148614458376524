import React, { FC } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import styled from "styled-components";
import useAppBreadcrumbs from "../../../hooks/useAppBreadcrumbs";
import BreadcrumbLink from "./BreadcrumbLink";

const BreadBox = styled(Container)`
  padding-left: 1rem;
`;

const base = { label: "Home", path: "/" };

const AppBreadcrumbs: FC = () => {
  const appBreadcrumbs = useAppBreadcrumbs({ base });

  return (
    <BreadBox>
      <Breadcrumb as="div">
        {appBreadcrumbs.map((breadcrumb) => (
          <BreadcrumbLink key={breadcrumb.path} to={breadcrumb.path}>
            {breadcrumb.label}
          </BreadcrumbLink>
        ))}
      </Breadcrumb>
    </BreadBox>
  );
};

export default AppBreadcrumbs;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pt50pUtils = void 0;
const lodash_1 = require("lodash");
const getAdjustedScheduleACost = (row) => {
    const previouslyReportedCost = (0, lodash_1.get)(row, "previouslyReportedCost", 0);
    const additionsOrTransfersIn = (0, lodash_1.get)(row, "additionsOrTransfersIn", 0);
    const disposalsOrTransfersOut = (0, lodash_1.get)(row, "disposalsOrTransfersOut", 0);
    return (Number(previouslyReportedCost) +
        Number(additionsOrTransfersIn) -
        Number(disposalsOrTransfersOut));
};
const getIndicatedScheduleACost = (row) => {
    const adjustedCost = getAdjustedScheduleACost(row);
    const convFactor = (0, lodash_1.get)(row, "compConvFactor", 1);
    return adjustedCost * Number(convFactor);
};
const getAdjustedScheduleACostTotal = (rows) => {
    if (!rows)
        return 0;
    return rows.reduce((acc, row) => {
        return acc + getAdjustedScheduleACost(row);
    }, 0);
};
const getIndicatedScheduleACostTotal = (rows) => {
    if (!rows)
        return 0;
    return rows.reduce((acc, row) => {
        return acc + getIndicatedScheduleACost(row);
    }, 0);
};
// So this value can be changed once everywhere, and so that it is flexible in
// implementation.
const getScheduleCMarketValueFactor = (year) => {
    return 0.75;
};
const getIndicatedScheduleCValue = (cost) => {
    return (0, lodash_1.round)(cost * getScheduleCMarketValueFactor(), 2);
};
exports.pt50pUtils = {
    getAdjustedScheduleACost,
    getIndicatedScheduleACost,
    getIndicatedScheduleCValue,
    getScheduleCMarketValueFactor,
    getAdjustedScheduleACostTotal,
    getIndicatedScheduleACostTotal,
};

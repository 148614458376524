const detectStage = () => {
  const { hostname } = window.location;

  if (hostname === "fc.taxscribe.app") {
    return "production";
  }

  if (hostname === "qa-fc.taxscribe.app") {
    return "qa";
  }

  if (hostname === "uat-fc.taxscribe.app") {
    return "uat";
  }

  return "development";
};

const configOpts = {
  production: {
    apolloUri: "",
    appName: "",
    googleConfig: {
      clientId: "",
    },
  },
  uat: {
    apolloUri: "https://uat-fc-api.taxscribe.app/graphql",
    appName: "UAT First Circuit",
    googleConfig: {
      clientId: "353149360865-0c4kb1kidfv84guumsusqc80896d0nf3.apps.googleusercontent.com",
    },
  },
  qa: {
    apolloUri: "https://qa-fc-api.taxscribe.app/graphql",
    appName: "QA First Circuit",
    googleConfig: {
      clientId: "353149360865-hmcprg1tqhdgvq7a7s4ili0o37tcqha8.apps.googleusercontent.com",
    },
  },
  development: {
    apolloUri: "http://localhost:4003/graphql",
    appName: "Dev ENV",
    googleConfig: {
      clientId: "353149360865-hmcprg1tqhdgvq7a7s4ili0o37tcqha8.apps.googleusercontent.com",
    },
  },
};

const releaseStage = detectStage();
const devStages = ["development", "qa", "uat"];
const devMode = devStages.includes(releaseStage);
const config = { ...configOpts[releaseStage], devMode, releaseStage };

export default config;

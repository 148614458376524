import { gql, useQuery } from "@apollo/client";
import { Timezone } from "../types/timezones";

const GET_TIMEZONE = gql`
  query GetTimezone{
    timezones {
      name
      abbrev
    }
  }
`;

const useTimezones = () => {
  return useQuery<{ timezones: Timezone[] }>(
    GET_TIMEZONE,
  );
};

export default useTimezones;

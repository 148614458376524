import { gql, useQuery } from "@apollo/client";
import { StenoTemplate } from "../types/stenoTemplates";

export const GET_STENO_TEMPLATES = gql`
  query GetStenoTemplates {
    stenoTemplates {
      name
      exists
    }
  }
`;

interface StenoTemplateResults {
  stenoTemplates: StenoTemplate[];
}

const useStenoTemplates = () => {
  return useQuery<StenoTemplateResults>(GET_STENO_TEMPLATES);
};

export default useStenoTemplates;

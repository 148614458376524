import React, { FC } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useJurisdiction from "../../../hooks/useJurisdiction";
import StandardPageContainer from "../../shared/StandardPageContainer";
import NewFormPeriod from "../FormPeriod/FormPeriodForm/NewFormPeriod";
import FormPeriods from "./FormPeriods";

const Jurisdiction: FC = () => {
  const { id } = useParams();
  const { data, loading } = useJurisdiction(Number(id));

  if (loading) {
    return (
      <StandardPageContainer>
        <Spinner animation="border" />
      </StandardPageContainer>
    );
  }

  const {
    name,
    stateAbbr,
    formPeriods,
  } = data?.jurisdiction || {};

  return (
    <StandardPageContainer>
      <Card>
        <Card.Header>
          <h2>Jurisdiction {id}: {data?.jurisdiction?.name || ""}</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={2}>
              <h3>Name</h3>
            </Col>
            <Col md={10}>
              {name}
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <h3>State</h3>
            </Col>
            <Col md={10}>
              {stateAbbr}
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <h3>ID</h3>
            </Col>
            <Col md={10}>
              {id}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <NewFormPeriod jurisdictionId={Number(id)} />
      <FormPeriods formPeriods={formPeriods} />
    </StandardPageContainer>
  );
};

export default Jurisdiction;

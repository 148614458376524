import { gql, useQuery } from "@apollo/client";
import { State } from "../types/states";

export const GET_STATES = gql`
  query GetStates {
    states {
      abbr
      name
      jurisdictionType
    }
  }
`;

const useStates = () => {
  return useQuery<{ states: State[] }>(GET_STATES);
};

export default useStates;

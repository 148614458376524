import { GoogleAuthBoundary, useGoogleContext } from "@reasongcp/react-fire-sub";
import React, { FC } from "react";
import { Button } from "react-bootstrap";

const SignOutButton: FC = () => {
  const { signOut } = useGoogleContext();

  return (
    <Button onClick={signOut}>Sign Out</Button>
  );
};

const SignInButton: FC = () => {
  const { loaded, googleSignIn } = useGoogleContext();

  if (!loaded) return null;

  return (
    <Button onClick={googleSignIn}>Sign In</Button>
  );
};

const SignInOrOut: FC = () => {
  return (
    <GoogleAuthBoundary PlaceHolder={SignInButton} >
      <SignOutButton />
    </GoogleAuthBoundary>
  );
};

export default SignInOrOut;

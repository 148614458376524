import { gql } from '@apollo/client';

const sectionOneEntryData = `
  merchandise
  rawMaterials
  workInProgress
  finishedGoods
  supplies
  total
`;
const LAT5_REPORT_DATA = gql `
  fragment LAT5FormData on LAT5Data {
    formType
    business {
      name
      assessmentNumber
      physicalAddress {
        street
        city
        state
        zipcode
      }
      mailingAddress {
        street
        city
        state
        zipcode
        sameAs
      }
      contact
      ward
      businessType
      phone
      email
    }
    sectionOne {
      january {
        ${sectionOneEntryData}
      }
      february {
        ${sectionOneEntryData}
      }
      march {
        ${sectionOneEntryData}
      }
      april {
        ${sectionOneEntryData}
      }
      may {
        ${sectionOneEntryData}
      }
      june {
        ${sectionOneEntryData}
      }
      july {
        ${sectionOneEntryData}
      }
      august {
        ${sectionOneEntryData}
      }
      september {
        ${sectionOneEntryData}
      }
      october {
        ${sectionOneEntryData}
      }
      november {
        ${sectionOneEntryData}
      }
      december {
        ${sectionOneEntryData}
      }
      methodOfReporting {
        method
        otherDescription
      }
    }
    sectionTwo {
      entries {
        year
        cost
        description
      }
    }
    sectionThree {
      entries {
        year
        cost
        description
      }
    }
    sectionFour {
      entries {
        item
        year
        cost
        description
      }
    }
    sectionFive {
      entries {
        item
        lessor
        phone
        consignedGoods
        lessorAddress {
          street
          city
          state
          zipcode
        }
      }
    }
    exemption {
      hasExemption
      sectionFour {
        entries {
          lessor
          description
          age
          monthlyRental
          sellingPrice
          lessorAddress {
            street
            city
            state
            zipcode
          }
        }
      }
      sectionOne {
        entries {
          year
          contractNumber
          cost
          exemptionAmount
          difference
        }
      }
      sectionThree {
        entries {
          year
          contractNumber
          description
          cost
          exemptionAmount
          difference
        }
      }
      sectionTwo {
        entries {
          year
          contractNumber
          description
          cost
          exemptionAmount
          difference
        }
      }
    }
    affirmation {
      preparerSignature
    }
  }
`;

const PT50P_DATA = gql `
  fragment PT50PFormData on PT50PData {
    formType
    pt50p {
      accountNumberPrimary
      taxpayerInformation {
        name
        address {
          street
          city
          state
          zipCode
        }
      }
      generalInformation {
        businessPhoneNumber
        homeOfficeNumber
        tollFreeNumber
        faxNumber
        email
        closedOrSold
        parcelNumber
        physicalAddress {
          street
          city
          state
          zipCode
        }
        mailingAddress {
          street
          city
          state
          zipCode
          sameAs
        }
        newOwnerInformation {
          name
          street
          city
          state
          zipCode
        }
        contactInformation {
          name
          phone
        }
        preparerInformation {
          name
          street
          city
          state
          zipCode
          phone
        }
        supportingRecords {
          street
          city
          state
          zipCode
        }
        typeOfBusiness
        typeOfIncome
        fiscalYearEndingDate
        federalEmployerIdentificationNumber
        stateTaxpayerIdentificationNumber
        stateSalesTaxNumber
        ownerOrCorporationPresident
        dbaName
        businessLicensedName
        businessCity
        squareFootageOfBuilding
        squareFootageOfRetailArea
        mainBusinessProductOrActivity
        naicsCode
        dateBusinessBeganInThisCounty
        hasAssetsInOtherCounties
        filedReturnLastYear
        ownsBoatAndMotor
        ownsAircraft
        hasFreeportExemption
      }
      scheduleA {
        group1 {
          yearAcquired
          previouslyReportedCost
          additionsOrTransfersIn
          disposalsOrTransfersOut
          compConvFactor
        }
        group2 {
          yearAcquired
          previouslyReportedCost
          additionsOrTransfersIn
          disposalsOrTransfersOut
          compConvFactor
        }
        group3 {
          yearAcquired
          previouslyReportedCost
          additionsOrTransfersIn
          disposalsOrTransfersOut
          compConvFactor
        }
        group4 {
          yearAcquired
          previouslyReportedCost
          additionsOrTransfersIn
          disposalsOrTransfersOut
          compConvFactor
        }
        ownedEquipment
      }
      scheduleB {
        inventory {
          merchandise
          rawMaterials
          goodsInProcess
          finishedGoods
          goodsInTransit
          warehoused
          consigned
          floorPlanned
          spareParts
          supplies
          packagingMaterials
          livestock
          accountingMethod
          fiscalYearEndDate
          costMethod
          priorYearInventoryReported
          grossSalesPreviousYear
        }
        ownedInventory
      }
      scheduleC {
        constructionInProgress {
          description
          yearAcquired
          usefulLife
          cost
        }
        additionsOrTransfers {
          description
          cost
          yearAcquired
        }
        consignedGoods {
          description
          cost
          owner {
            name
            street
            city
            state
            zip
          }
        }
        hasUnallocatedCosts
        hasConnectedTangiblePersonalProperty
        hasAdditionsOrTransfers
        hasConsignedGoods
        hasDisposalsOrTransfers
        hasLeasedEquipment
        disposalsOrTransfers {
          description
          yearAcquired
          cost
          dateDispossessed
          reason
          purchaser {
            name
            street
            city
            state
            zip
          }
        }
        leasedEquipment {
          description
          cost
          owner {
            name
            street
            city
            state
            zip
          }
          monthlyRent
          manufactureDate
          installDate
          leaseLength
        }
      }
      freeportInventory {
        indicatedValue
      }
      personalPropertyStrata {
        equipment
        inventory
        freeport
        other
      }
      affirmation {
        preparerTitle
        preparerPhone
        preparerEmail
        preparerName
        preparerSignature
      }
      attachments {
        fileName
        uuid
      }
    }
  }
`;

const PT50M_DATA = gql `
  fragment PT50MFormData on PT50MData {
    formType
    pt50m {
      accountNumberPrimary
      taxpayerInformation {
        name
        address {
          street
          city
          state
          zipCode
        }
        situsCity
        taxSitus
      }
      boats {
        county
        returnValue
        accessories
        boat {
          soldBoat
          registrationNumber
          make
          model
          year
          length
          hullMaterial
          datePurchased
          purchased
          cost
          totalCost
          boatType
          otherBoatTypeDescription
          hasFunctionalIssues
          functionalIssues
          purchasedBoat
          purchaser {
            name
            street
            city
            state
            zipCode
            salePrice
            date
          }
          seller {
            name
            street
            city
            state
            zipCode
          }
        }
        motor {
          make
          model
          year
          horsepower
          startType
          datePurchased
          purchased
          cost
        }
      }
      federalDocumentedVessels {
        returnValue
        typeAndUseOfVessel
        vesselName
        length
        yearBuilt
        hullMaterial
        horsepowerAndType
        coastGuardNumber
        yearPurchased
        cost
        purchased
        homePort
        whereDocked
      }
      affirmation {
        homePhone
        dayPhone
        preparerTitle
        preparerEmail
        preparerSignature
      }
    }
  }
`;

const PT50A_DATA = gql `
  fragment PT50AFormData on PT50AData {
    formType
    pt50a {
      accountNumberPrimary
      taxpayerInformation {
        name
        address {
          street
          city
          state
          zipCode
        }
        situsCity
        taxSitus
      }
      aircraft {
        city
        county
        state
        registrationNumber
        make
        model
        year
        serialNumber
        datePurchased
        purchaseCondition
        cost
        hoursBetweenOverhauls
        hoursSinceLastOverhaul
        lastOverhaul
        hoursOnAirframe
        hasFunctionalIssues
        functionalIssues
        soldAircraft
        purchasedAircraft
        currentYearValue
        purchaser {
          name
          street
          city
          state
          zipCode
          salePrice
          date
          description
        }
        seller {
          name
          street
          city
          state
          zipCode
        }
        avionicsAndEquipment {
          description
        }
      }
      affirmation {
        preparerTitle
        homePhone
        dayPhone
        preparerEmail
        preparerSignature
      }
    }
  }
`;

const PT50PF_DATA = gql `
  fragment PT50PFFormData on PT50PFData {
    formType
    pt50pf {
      naicsCode
      parcelNumber
      accountNumberPrimary
      taxpayerInformation {
        name
        address {
          street
          city
          state
          zipCode
        }
      }
      typeOfBusiness
      inventoryValuationMethod
      inventoryCostIdentificationMethod
      totalAllInventory
      totalStockInTrade
      hasSupportingRecords
      supportingRecords {
        street
        city
        state
        zipCode
      }
      contactInformation {
        firstName
        lastName
        phone
      }
      physicalLocations {
        street
        city
        state
        zipCode
      }
      freeportLevelOne {
        finishedGoods
        packagingMaterials
        otherExpensedSupplies
        spareParts
        combinedCost
        exemptionAmount
        categoryOne {
          cost
          exemptionPercentage
        }
        categoryTwo {
          cost
          exemptionPercentage
        }
        categoryThree {
          cost
          exemptionPercentage
          shipmentsCompleteYear
          outOfStateShipments
          inventoryJanFirst
        }
        categoryFour {
          cost
          exemptionPercentage
        }
      }
      freeportLevelTwo {
        totalCostOfAllMerchandise
        appropriateExemption
      }
      attachments {
        fileName
        uuid
      }
      affirmation {
        preparerTitle
        preparerEmail
        preparerSignature
      }
    }
  }
`;

const NC_LISTING_DATA = gql `
  fragment NCListingFormData on NCListingData {
    formType
    business {
      name
      accountNumber
      dbaCheckbox
      dbaName
      accountPrimary
      accountSecondary
      jurisdictionName
      year
      mailingAddress {
        addressCheckbox
        street
        city
        state
        zipcode
      }
      physicalAddress {
        street
        city
        state
        zipcode
      }
      ownedBy
      previousName
      personalPropertyCounties
      principalBusiness
      sicCode
      naicsCode
      startedDate
      fiscalYearEnd
      typeOf
      typeOfExplanation
      category
      categoryExplanation
      outOrInBusiness
      outOfBusiness {
        reason
        ceased
        buyer
        buyerAddress
        buyerPhone
      }
    }
    contact {
      auditContact {
        name
        phoneNumber
        street
        city
        state
        zipcode
      }
      paymentContact {
        name
        phoneNumber
        street
        city
        state
        zipcode
      }
    }
    scheduleA {
      group1 {
        na
        PropertyPK
        grid {
          year
          additions
          deletions
          prior
        }
      }
      group2 {
        constructionInProgress
      }
      group3 {
        na
        PropertyPK
        grid {
          year
          additions
          deletions
          prior
        }
      }
      group4 {
        na
        PropertyPK
        grid {
          year
          additions
          deletions
          prior
        }
      }
      group5 {
        na
        PropertyPK
        grid {
          year
          additions
          deletions
          prior
        }
      }
      group6 {
        na
        PropertyPK
        capitalizationThreshold
        grid {
          year
          additions
          deletions
          prior
        }
      }
      group7 {
        supplies
        fuelsHeld
        parts
        restaurantHotelItems
        rentalItems
        other
        PropertyPK
      }
      group8 {
        PropertyPK
        grid {
          year
          additions
          deletions
          prior
        }
      }
    }
    scheduleB {
      ownsUnregisteredMotorVehicle
      ownsMultiYearOrRegisteredTrailer
      ownsSpecialBodiesOnVehicle
      ownsIRPPlatedVehicle
      b1s {
        PropertyPk
        year
        make
        model
        bodySize
        titleNumber
        vin
        cost
      }
      b1Attachment {
        fileName
        uuid
      }
      watercraft
      b2s {
        PropertyPk
        typeOf
        year
        make
        model
        lengthSize
        registrationNumber
        location
        marinaName
        slipNumber
        slipExtension
        engineType
        yearAcquired
        originalCost
      }
      b2Attachment {
        fileName
        uuid
      }
      mobileHome
      b3s {
        PropertyPk
        year
        make
        widthLength
        width
        length
        vin
        location
        yearAcquired
        originalCost
        lotNumber
        lotExtension
      }
      b3Attachment {
        fileName
        uuid
      }
      aircraft
      b4s {
        PropertyPk
        year
        make
        model
        numberOrTailNumber
        location
        yearAcquired
        originalCost
        hangarNumber
        hangarExtension
      }
      b4Attachment {
        fileName
        uuid
      }
      shortTermRental
      numberVehicles
    }
    scheduleC {
      ownsLeasedProperty
      usesOthersProperty
      operatesAsFacility
      c1s {
        ownerName
        ownerAddress
        description
        leaseDate
        monthlyPayment
        lengthOfLease
        accountOrLeaseNumber
        sellingPriceNew
      }
      c1Attachment {
        fileName
        uuid
      }
    }
    scheduleD {
      ownsSeparatelyScheduledProperty
      d1s {
        description
        estimatedValue
      }
      d1Attachment {
        fileName
        uuid
      }
    }
    scheduleE {
      costOnScheduleA
      ownsFarmEquipment
      e1s {
        year
        PropertyPK
        descriptionOrMake
        modelOrSeries
        fuelType
        yearAcquired
        originalCost
      }
      e1Attachment {
        fileName
        uuid
      }
    }
    scheduleF {
      leaseProperty
      f1s {
        ownerName
        ownerAddress
        description
        leaseDateAndTerm
        monthlyPayment
        accountNumber
      }
      f1Attachment {
        fileName
        uuid
      }
    }
    scheduleG {
      acquisitionsDisposals
      g1s {
        acquisitions
        acquisitionOriginalCost
        disposals
        yearAcquired
        disposalsOriginalCost
      }
      g1Attachment {
        fileName
        uuid
      }
    }
    scheduleH {
      realEstateImprovements
      h1s {
        locationParcelNumber
        description
        constructionCost
        percentCompleteOnJan1
      }
      h1Attachment {
        fileName
        uuid
      }
    }
    scheduleI {
      ownsAdvertisingStructures
      i1s {
        panelNumbers
        DOTPermitNumber
        location
        cityDistrictNone
        buildDate
        classAndType
        haglFeet
        displaySpaceSqFt
        countyIdNumber
        displayIsIlluminated
        originalCost
      }
      i1Attachment {
        fileName
        uuid
      }
    }
    scheduleJ {
      leaseEquipment
      j1s {
        taxingDistrict
        lesseeName
        lesseeAddress
        propertyAddress
        assetNumber
        leaseNumber
        assessorAcctNumber
        itemDescription
        acquisitionYear
        installedCost
        propertyClassification
      }
      j1Attachment {
        fileName
        uuid
      }
    }
    attachments {
      fileName
      uuid
    }
    affirmation {
      individualTaxpayers
      corporationsEtc
      fullTimeEmployeeTitle
      principalOfficeTaxpayerTitle
      businessType
      name
      signature
      date
      phoneNumber
      faxNumber
      email
      authorizedAgent
    }
  }
`;

const MI_LISTING_DATA = gql `
  fragment MIListingFormData on MIListingData {
    formType
    exemption {
      hasExemption
      exemptionAttachment {
        uuid
        fileName
      }
    }
    businessInfo {
      parcelNumber
      taxPayerInfo {
        name
        address
        city
        zipCode
        stateAbbr
        phoneNumber
        email
        businessActivity
      }
      assessorInfo {
        name
        jurisdictionName
        address
        city
        zipCode
        stateAbbr
        phoneNumber
      }
      preparerInfo {
        name
        address
        city
        zipCode
        stateAbbr
        phoneNumber
        email
      }
      propertyLocations {
        address
        city
        zipCode
        stateAbbr
        phoneNumber
      }
      businessInfo {
        businessType
        miCorporateId
        michiganBusiness
        naicsCode
        squareFeetOccupied
        address
        city
        zipCode
        stateAbbr
        businessStartDates {
          atLocation
          organizationStartDate
        }
        taxpayer {
          name
        }
        assumedNames {
          dbaName
        }
        residentialAddress {
          address
          city
          zipCode
          stateAbbr
        }
        partnerNames {
          name
        }
        auditContactInfo {
          contactName
          phoneNumber
          location {
            address
            city
            zipCode
            stateAbbr
          }
        }
      }
    }
    summaryAndCertification {
      exemptSpecialTools
      costExcluded
      environmentalExclusions
      exclusionsList {
        certificateNumber
        dateOfIssuance
        amount
      }
      tangiblePropertyExplanation
      tangiblePropertyReported
      equitableInterest
      equitableInterestList {
        interestHolders
        interest
        equipmentDescription
        year
        sellingPrice
        propertyLocation
        city
        stateAbbr
        zipCode
      }
      rentalAgreement
      rebookingCosts
      rebookingCostsList {
        description
        newYear
        section
        acquisitionYear
        amount
        explanation
      }
      dailyRental
      dailyRentalPropertiesList {
        idNumber
        description
        manufacturerName
        make
        model
        serialNumber
        yearInService
        cost
      }
      otherOperatingBusinesses
      otherOperatingBusinessesList {
        name
      }
      constructionInProgress
      cellularSiteEquipment
      cableUtilityAndWindAssets
      idleEquipment
    }
    moveIns {
      hasMoveIns
      moveInsAttachment {
        fileName
        uuid
      }
    }
    sectionA {
      rows {
        year
        value
        rate
        total
      }
    }
    sectionB {
      rows {
        year
        value
        rate
        total
      }
    }
    sectionC {
      rows {
        year
        value
        rate
        total
      }
    }
    sectionD {
      rows {
        year
        value
        rate
        total
      }
    }
    sectionE {
      rows {
        year
        value
        rate
        total
      }
    }
    sectionF {
      rows {
        year
        value
        rate
        total
      }
    }
    sectionG {
      otherPropertyInfo {
        description
        costNew
        yearAcquired
        cashValue
      }
    }
    sectionH {
      standardToolingInfo {
        year
        costNew
      }
    }
    sectionI {
      personalPropertyInfo {
        equipmentInfo
        name
        address
        stateAbbr
        zipCode
        city
        costInstalled
        installationDate
        leaseTermInMonths
        manufactureYear
        averageMonthlyRental
      }
    }
    sectionJ {
      personalPropertyInfo {
        leaseNumber
        name
        address
        stateAbbr
        zipCode
        city
        description
        leaseTermInMonths
        monthlyRental
        firstServiceYear
        sellingPriceNew
      }
    }
    sectionK {
      personalPropertyInfo {
        name
        address
        city
        stateAbbr
        zipCode
        description
        age
        sellingPriceNew
      }
    }
    sectionL {
      isManufacturer
      manufacturerAttachment {
        fileName
        uuid
      }
      leaseEquipmentInfo {
        name
        address
        city
        stateAbbr
        zipCode
        leaseNumber
        location
        equipmentType
        leasePeriod
        monthlyRental
        firstServiceYear
        manufactureCost
        originalSellingPrice
      }
    }
    sectionM {
      leaseHoldImprovements {
        description
        year
        originalCost
      }
    }
    sectionN {
      alreadyAssessed
      towersAttachment {
        fileName
        uuid
      }
      structures {
        address
        city
        zipCode
        stateAbbr
        yearBuilt
        totalCapitalizedCost
      }
    }
    sectionO {
      isLandlordLesseeOrSublessee
      tenant
      landlord
      landlordInfo {
        name
        address
        city
        zipCode
        stateAbbr
        landlordOwned
        ownerInfo {
          name
          address
          city
          zipCode
          stateAbbr
        }
      }
      tenantInfo {
        name
        address
        city
        zipCode
        stateAbbr
        landlordOwned
        ownerInfo {
          name
          address
          city
          zipCode
          stateAbbr
        }
      }
      leasedPropertyInfo {
        address
        city
        zipCode
        stateAbbr
        rentalStartDate
        squareFeetOccupied
        monthlyRentalAmount
        leaseExpirationDate
        renewalOptions
        tenantPaidExpenses
      }
    }
    attachments {
      fileName
      uuid
    }
  }
`;

const NC_EXTENSION_DATA = gql `
  fragment NCExtensionFormData on NCExtensionData {
    formType
    businessName
    accountNumber
    accountNumberSecondary
    mailingAddress {
      address
      city
      state
      zip
    }
    physicalAddress {
      address
      city
      state
      zip
    }
    extensionRequestReason
    signature
  }
`;

const MI_EXEMPTION_DATA = gql `
  fragment MIExemptionFormData on MIExemptionData {
    businessName
    parcelNumber
    address
    city
    state
    zipCode
    exemptionAttachment {
      uuid
      fileName
    }
  }
`;

const NC_IPP_LISTING_DATA = gql `
  fragment NCIPPListingFormData on NCIPPListingData {
    formType
    ncIppListing {
      ownershipInformation {
        firstName
        lastName
        middleName
        suffix
        additionalName
        accountNumber
        email
        phoneNumber
        addressOne
        addressTwo
        city
        state
        zipCode
      }
      contactInformation {
        firstName
        lastName
        middleName
        suffix
        additionalName
        businessName
        phoneType
        phoneNumber
        email
      }
      physicalLocation {
        allPropertiesPopulatedAddress
        addressOne
        addressTwo
        city
        state
        zipCode
      }
      aircraft {
        needToAdd
        aircraft {
          year
          body
          make
          model
          cost
          yearAcquired
          airport
          hangarNumber
          tailNumber
        }
      }
      vehicleUnregistered {
        needToAdd
        vehicles {
          year
          make
          model
          body
          vin
          yearAcquired
          cost
          length
          width
        }
      }
      vehiclePermanentPlate {
        needToAdd
        vehicles {
          year
          make
          model
          body
          vin
          yearAcquired
          cost
          length
          width
          plateNumber
          titleNumber
          regisrationIssueDate
        }
      }
      manufacturedHome {
        needToAdd
        homes {
          parcelId
          year
          make
          model
          body
          width
          length
          yearAcquired
          cost
          vin
          serialNumber
          plateNumber
          manufacturedHomePark
          lotNumber
          titleNumber
          occupant
          titleNameOne
          titleNameTwo
          movingPermitNumber
          movingPermitIssueDate
          buildingPermitNumber
          buildingPermitIssueDate
        }
      }
      watercraft {
        needToAdd
        watercraft {
          year
          make
          model
          length {
            feet
            inches
          }
          registrationNumber
          fuelCode
          modelCode
          propulsionCode
          primaryUseCode
          yearAcquired
          cost
          marina
          motorMakeOne
          motorMakeTwo
          slipNumber
          motorHpOne
          motorHpTwo
          slipNumberExtension
          motorSerialNumberOne
          motorSerialNumberTwo
        }
      }
      attachments {
        fileName
        uuid
      }
      affirmation {
        firstName
        lastName
        phoneNumber
        email
        signature
      }
    }
  }
`;

const GA_HOMESTEAD_EXEMPTION_DATA = gql `
  fragment GAHomesteadExemptionFormData on GAHomesteadExemptionData {
    formType
    sectionA {
      applicantInfo {
        address
        carCounty
        city
        name
        overAge
        residentOrAuthorizedAlien
        authorizedAlienRegistrationNumber
        state
        votingCounty
        zipCode
        disabledVeteran
        survivingSpouseKia
        survivingSpousePublicService
        otherAddresses {
          address
          city
          state
          zip
        }
      }
      spouseInfo {
        address
        city
        name
        state
        votingCounty
        zipCode
      }
      militaryService {
        state
      }
      otherAddresses {
        address
        city
        state
        zip
      }
    }
    sectionB {
      businessExplanation
      dateOfPurchase
      deedBook
      landDistrictNumber
      lienAmount
      lienHolder
      location
      lotSize
      page
      parcelNumber
      propertyRented
      propertyUsedForBusiness
      purchasePrice
      purchasedFrom
      rentalExplanation
      titleType
    }
    sectionC1 {
      deduction {
        applicant
        spouse
      }
      otherIncome {
        applicant
        spouse
      }
      personalExemption {
        applicant
        spouse
      }
      retirementIncome {
        applicant
        spouse
      }
      socialSecurityIncome {
        applicant
        spouse
      }
      socialSecurityMax {
        applicant
        spouse
      }
    }
    sectionC2 {
      householdMembers {
        name
        federalAdjustedGrossIncome
      }
    }
    signature
  }
`;

const LA_HOMESTEAD_EXEMPTION_DATA = gql `
  fragment LAHomesteadExemptionFormData on LAHomesteadExemptionData {
    formType
    tc75 {
      testingOut
      name
      street
      city
      state
      zipCode
      subdivision
      block
      lot
      secTownshipRange
      assessmentNumber
      parish
      bookPage
      entryNumber
      dateOfOccupancy
      homesteadEffectiveDate
      proof {
        fileName
        uuid
      }
      qualifications {
        standard
        withUseValue
        survivingSpouse
        irrevocableTrust
        usufruct
        ownedInIndivision
        ownedInIndivisionPercentage
        bondForDeed
        additionalHE
      }
    }
  }
`;

const ALL_FORM_DATAS_FRAGMENT = gql `
  ${LAT5_REPORT_DATA}
  ${PT50P_DATA}
  ${PT50M_DATA}
  ${PT50A_DATA}
  ${PT50PF_DATA}
  ${NC_LISTING_DATA}
  ${MI_LISTING_DATA}
  ${NC_EXTENSION_DATA}
  ${MI_EXEMPTION_DATA}
  ${NC_IPP_LISTING_DATA}
  ${GA_HOMESTEAD_EXEMPTION_DATA}
  ${LA_HOMESTEAD_EXEMPTION_DATA}
  fragment FormDataFragment on TaxForm {
    data {
      formType
      ... on NCListingData {
        ...NCListingFormData
      }
      ... on NCExtensionData {
        ...NCExtensionFormData
      }
      ... on MIListingData {
        ...MIListingFormData
      }
      ... on MIExemptionData {
        ...MIExemptionFormData
      }
      ... on LAT5Data {
        ...LAT5FormData
      }
      ... on GAHomesteadExemptionData {
        ...GAHomesteadExemptionFormData
      }
      ... on PT50MData {
        ...PT50MFormData
      }
      ... on PT50AData {
        ...PT50AFormData
      }
      ... on PT50PData {
        ...PT50PFormData
      }
      ... on PT50PFData {
        ...PT50PFFormData
      }
      ... on LAHomesteadExemptionData {
        ...LAHomesteadExemptionFormData
      }
      ... on NCIPPListingData {
        ...NCIPPListingFormData
      }
    }
  }
`;

const getFormPeriodForForm = gql `
  query FormPeriod($uuid: String!) {
    form(uuid: $uuid) {
      id
      ddbKey
      formPeriod {
        id
        extendedDeadline
        filingDeadline
        filingDeadlineTimestamp
        filingYear
        jurisdictionId
        periodStart
        periodStartTimestamp
        timezone
      }
    }
  }
`;

const TAX_FORM_METADATA_FRAGMENT = gql `
  fragment TaxFormMetadataFragment on TaxForm {
    id
    ddbKey
    source
    ddbTable
    shortName
    createdAt
    updatedAt
    archived
    filingYear
    createdById
    formPeriodId
    formPeriod {
      filingDeadline
      filingDeadlineTimestamp
      filingDeadlineFormattedDay
    }
    businessCity
    businessName
    businessAddress
    accountNumberPrimary
    accountNumberSecondary
    currentSubmission {
      id
      formId
      email
      pdfUrl
      createdAt
      updatedAt
      syncStatus
      contactName
      submittedAt
      status
      statusLastChangedById
      jurisdictionRespondedAt
    }
  }
`;

const GetMyForm = gql `
  ${TAX_FORM_METADATA_FRAGMENT}
  ${ALL_FORM_DATAS_FRAGMENT}
  query GetMyForm($uuid: String!) {
    form(uuid: $uuid) {
      ...TaxFormMetadataFragment
      ...FormDataFragment
    }
  }
`;

const ARCHIVE_FORM = gql `
  mutation ArchiveForm($uuid: String!, $unArchive: Boolean) {
    archiveForm(uuid: $uuid, unArchive: $unArchive)
  }
`;

const REGENERATE_PDF = gql `
  mutation RegeneratePdf($uuid: String, $id: Int) {
    regeneratePdf(uuid: $uuid, id: $id)
  }
`;

const GET_PDF_URL = gql `
  query GetPdfUrl($uuid: String!) {
    form(uuid: $uuid) {
      ddbKey
      currentSubmission {
        pdf {
          url
        }
      }
    }
  }
`;

const MY_JURISDICTION_FORMS = gql `
  query MyJurisdictionForms(
    $limit: Int
    $before: Int
    $offset: Int
    $filingYear: Int
    $isArchived: Boolean
    $formStatus: FormStatus
    $formType: String
    $formGroup: String
    $jurisdictionQuery: JurisdictionQuery
  ) {
    myForms(
      limit: $limit
      offset: $offset
      before: $before
      status: $formStatus
      formType: $formType
      formGroup: $formGroup
      filingYear: $filingYear
      isArchived: $isArchived
      jurisdictionQuery: $jurisdictionQuery
    ) {
      id
      ddbKey
      archived
      shortName
      filingYear
      businessName
      businessCity
      businessAddress
      accountNumberPrimary
      accountNumberSecondary
      status
      messages {
        id
        formId
        tag
        read
        detail
        createdAt
        updatedAt
        senderId
        source
        contactName
      }
      currentSubmission {
        id
        email
        formId
        contactName
        submittedAt
        status
      }
    }
  }
`;

const MY_JURISDICTION_FORMS_COUNT = gql `
  query JurisdictionFormsCount(
    $limit: Int
    $before: Int
    $offset: Int
    $filingYear: Int
    $isArchived: Boolean
    $formStatus: FormStatus
    $formType: String
    $jurisdictionQuery: JurisdictionQuery
  ) {
    countMyForms(
      limit: $limit
      offset: $offset
      before: $before
      status: $formStatus
      formType: $formType
      filingYear: $filingYear
      isArchived: $isArchived
      jurisdictionQuery: $jurisdictionQuery
    )
  }
`;

const GET_CREDIT_COUNT = gql `
  query GetCreditCount($uuid: String!) {
    organization(uuid: $uuid) {
      uuid
      creditCount {
        used
        purchased
        available
        promotional
      }
    }
  }
`;

const GET_EFILINGS = gql `
  query Efilings($formGroup: String) {
    efilings(formGroup: $formGroup) {
      id
      jurisdictionId
      formGroupId
      createdAt
      updatedAt
      jurisdiction {
        id
        jurisdictionType
        name
        stateAbbr
      }
      formGroup {
        id
        name
        description
      }
    }
  }
`;

const SEND_MESSAGE = gql `
  mutation SendMessage($uuid: String!, $detail: String!) {
    createFormMessage(uuid: $uuid, detail: $detail)
  }
`;

const READ_MESSAGES = gql `
  mutation READ_MESSAGES($uuid: String!) {
    readFormMessages(uuid: $uuid)
  }
`;

const GET_FORM_MESSAGES = gql `
  query GetFormMessages($uuid: String!) {
    form(uuid: $uuid) {
      id
      ddbKey
      messages {
        id
        formId
        tag
        read
        detail
        createdAt
        updatedAt
        senderId
        contactName
        source
      }
    }
  }
`;

const CREATE_ORGANIZATION_PAYMENT_PROFILE = gql `
  mutation CreateOrganizationPaymentProfile($profile: PaymentProfileInput) {
    createOrganizationPaymentProfile(profile: $profile) {
      creditCard {
        cardType
        name
      }
      address {
        firstName
        lastName
        address
        city
        stateAbbr
        zipCode
      }
      id
    }
  }
`;

const GET_ORGANIZATION_PAYMENT_PROFILES = gql `
  query GetOrganizationPaymentProfiles($uuid: String!) {
    organization(uuid: $uuid) {
      paymentProfiles {
        id
        isDefault
        address {
          firstName
          lastName
          address
          city
          stateAbbr
          zipCode
        }
        creditCard {
          cardType
          name
          expiration
        }
      }
    }
  }
`;

const CHARGE_ORGANIZATION_PAYMENT_PROFILE = gql `
  mutation ChargeOrganizationPaymentProfile($profileId: Int!, $quantity: Int!) {
    chargeOrganizationPaymentProfile(
      profileId: $profileId
      quantity: $quantity
    ) {
      responseCode
      totalAmount
      transactionId
    }
  }
`;

const REMOVE_ORGANIZATION_PAYMENT_PROFILE = gql `
  mutation RemoveOrganizationPaymentProfile($profileId: Int!) {
    removeOrganizationPaymentProfile(profileId: $profileId)
  }
`;

const GET_ORGANIZATION_PAYMENTS = gql `
  query GetOrganizationPayments($uuid: String!) {
    organization(uuid: $uuid) {
      uuid
      paymentTransactions {
        id
        volume
        paymentAmount
        paymentResponseStatus
        paymentTransactionUid
        paymentProfileId
        createdAt
        updatedAt
        success
      }
    }
  }
`;

const SET_DEFAULT_PAYMENT_PROFILE = gql `
  mutation SetDefaultPaymentProfile($profileId: Int!) {
    setDefaultPaymentProfile(id: $profileId)
  }
`;

const TAXROLL_SEARCH = gql `
  query TaxrollSearch(
    $limit: Int
    $offset: Int
    $filingYear: Int
    $formGroup: String
    $searchTerm: String
    $jurisdictionId: Int!
  ) {
    jurisdiction(id: $jurisdictionId) {
      id
      name
      hasEfile
      parentId
      stateAbbr
      jurisdictionType
      taxrollSearch(
        limit: $limit
        offset: $offset
        formGroup: $formGroup
        filingYear: $filingYear
        searchTerm: $searchTerm
      ) {
        total
        accounts {
          id
          filingYear
          jurisdictionId
          accountNumberPrimary
          accountNumberSecondary
          businessName
          businessAddress
          businessCity
          createdAt
          updatedAt
          currentForms {
            id
            ddbKey
            status
            ddbTable
            formType
          }
        }
      }
    }
  }
`;

const GET_CURRENT_YEAR = gql `
  query GetCurrentYear {
    currentYear
  }
`;

export { ALL_FORM_DATAS_FRAGMENT, ARCHIVE_FORM, CHARGE_ORGANIZATION_PAYMENT_PROFILE, CREATE_ORGANIZATION_PAYMENT_PROFILE, GA_HOMESTEAD_EXEMPTION_DATA, GET_CREDIT_COUNT, GET_CURRENT_YEAR, GET_EFILINGS, GET_FORM_MESSAGES, getFormPeriodForForm as GET_FORM_PERIOD_FOR_FORM, GetMyForm as GET_MY_FORM, GET_ORGANIZATION_PAYMENTS, GET_ORGANIZATION_PAYMENT_PROFILES, GET_PDF_URL, LAT5_REPORT_DATA as LAT5_FORM_DATA, LA_HOMESTEAD_EXEMPTION_DATA, MI_EXEMPTION_DATA, MI_LISTING_DATA, MY_JURISDICTION_FORMS, MY_JURISDICTION_FORMS_COUNT, NC_EXTENSION_DATA, NC_IPP_LISTING_DATA, NC_LISTING_DATA, PT50A_DATA as PT50A_FORM_DATA, PT50M_DATA as PT50M_FORM_DATA, PT50PF_DATA as PT50PF_FORM_DATA, PT50P_DATA as PT50P_FORM_DATA, READ_MESSAGES, REGENERATE_PDF, REMOVE_ORGANIZATION_PAYMENT_PROFILE, SEND_MESSAGE, SET_DEFAULT_PAYMENT_PROFILE, TAXROLL_SEARCH, TAX_FORM_METADATA_FRAGMENT };

import React, { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";

import TableWrapper from "./TableWrapper";
import FilterSetWrapper from "../../shared/FilterSet/FilterSetWrapper";
import useJurisdictions from "../../../hooks/useJurisdictions";
import { Jurisdiction } from "../../../types/jurisdictions";
import useFilterSetContext from "../../../hooks/useFilterSetContext";

const jurisdictionAttributes: [string, string][] = [
  ["stateAbbr", "State"],
  ["name", "Name"],
  ["id", "ID"],
];

const JurisdictionRow: FC<Jurisdiction> = ({ id, name, stateAbbr }) => {
  return (
    <tr>
      <td>
        <div>{stateAbbr}</div>
      </td>
      <td>
        <div>{name}</div>
      </td>
      <td>
        <div>
          <Link to={`/jurisdictions/${id}`}>{id}</Link>
        </div>
      </td>
    </tr>
  );
};

const nameConversions: Record<string, string> = {
  States: "stateAbbrs",
  IDs: "ids",
  Names: "names",
};

const useFiltersAsMulti = () => {
  const { filters } = useFilterSetContext();
  return useMemo(() => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
      if (!value) return acc;
      const queryKey = nameConversions[key] || key;
      if (value && !Array.isArray(value)) {
        return {
          ...acc,
          [queryKey]: [value],
        };
      }
      return {
        ...acc,
        [queryKey]: value,
      };
    }, {});
  }, [filters]);
};

const JurisdictionTable: FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const selectFilters = useFiltersAsMulti();
  const variables = useMemo(
    () => ({ ...selectFilters, searchTerm }),
    [searchTerm, selectFilters],
  );
  const { data, loading } = useJurisdictions(variables);

  if (loading) {
    return (
      <TableWrapper
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      >
        <Spinner animation="border" />
      </TableWrapper>
    );
  }

  return (
    <TableWrapper
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    >
      <Table
        striped
        bordered
      >
        <thead>
          <tr>
            {jurisdictionAttributes.map(([key, label]) => (
              <th key={key}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.jurisdictions.map((j) => (
            <JurisdictionRow
              key={j.id}
              {...j}
            />
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const Jurisdictions: FC = () => {
  return (
    <FilterSetWrapper>
      <JurisdictionTable />
    </FilterSetWrapper>
  );
};

export default Jurisdictions;

import React, { FC } from "react";
import { Table, Card, Col, Row } from "react-bootstrap";

import useStenoTemplates from "../../../hooks/useStenoTemplates";
import StandardPageContainer from "../../shared/StandardPageContainer";
import PresenceIndicator from "./PresenceIndicator";

const StenoTemplates: FC = () => {
  const { data } = useStenoTemplates();

  return (
    <StandardPageContainer>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <h1>Steno Templates</h1>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table
            striped
            bordered
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Available</th>
              </tr>
            </thead>
            <tbody>
              {data?.stenoTemplates?.map((template) => (
                <tr key={template.name}>
                  <td>{template.name}</td>
                  <td>
                    <PresenceIndicator {...template} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </StandardPageContainer>
  );
};

export default StenoTemplates;

import { GoogleAuthBoundary } from "@reasongcp/react-fire-sub";
import React, { FC, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router";
import FormPeriod from "../../pages/FormPeriod";
import Home from "../../pages/Home";
import Jurisdiction from "../../pages/Jurisdiction";
import Jurisdictions from "../../pages/Jurisdictions";
import StenoTemplates from "../../pages/StenoTemplates";

interface PageDefinition {
  path: string;
  title: string;
  Component: FC;
}

const unAuthenticatedPages: PageDefinition[] = [
  {
    path: "/",
    title: "Home",
    Component: Home,
  },
];

const authenticatedPages: PageDefinition[] = [
  {
    path: "/jurisdictions",
    title: "Jurisdictions",
    Component: Jurisdictions,
  },
  {
    path: "/jurisdictions/:id",
    title: "Jurisdiction",
    Component: Jurisdiction,
  },
  {
    path: "/form-periods/:id",
    title: "Form Period",
    Component: FormPeriod,
  },
  {
    path: "/steno-templates",
    title: "Steno Templates",
    Component: StenoTemplates,
  },
];

export const pages = [
  ...unAuthenticatedPages,
  ...authenticatedPages,
];

const NotFound: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return null;
};

const mapToRoute = ({ path, Component }: PageDefinition) => (
  <Route key={path} path={path} element={<Component />} />
);

const AppRoutes: FC = () => {
  return (
    <Routes>
      {unAuthenticatedPages.map(mapToRoute)}
      <Route path="*" element={
        <GoogleAuthBoundary>
          <Routes>
            {authenticatedPages.map(mapToRoute)}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </GoogleAuthBoundary>
      } />
    </Routes>
  );
};

export default AppRoutes;

import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useFormPeriod from "../../../hooks/useFormPeriod";
import StandardPageContainer from "../../shared/StandardPageContainer";
import UpdateFormPeriod from "./FormPeriodForm/UpdateForm";

interface FormToggleProps {
  id: number;
  edit: boolean;
  afterUpdate: () => void;
}

const StyledHeaderCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const FormToggle: FC<FormToggleProps> = ({ id, edit, afterUpdate }) => {
  if (!edit) return null;

  return (
    <UpdateFormPeriod
      id={id}
      afterUpdate={afterUpdate}
    />
  );
};

interface DataRowProps {
  label: string;
  value: string;
  valueType?: "date";
}

const DataRow: FC<DataRowProps> = ({ label, value, valueType }) => {
  const formattedValue = useMemo(() => {
    if (valueType === "date") {
      const dateNumber = Number(value);
      if (!isNaN(dateNumber)) return new Date(dateNumber).toDateString();

      const date = new Date(value);
      return date.toDateString();
    }

    return value;
  }, [value, valueType]);

  return (
    <Row>
      <Col md={2}>
        <b>{label}</b>
      </Col>
      <Col md={10}>{formattedValue}</Col>
    </Row>
  );
};

const FormPeriod: FC = () => {
  const { id } = useParams();
  const numberId = Number(id);
  const [edit, setEdit] = useState(false);
  const { data, loading } = useFormPeriod(Number(id));
  const closeEdit = useCallback(() => {
    setEdit(false);
  }, [setEdit]);
  const toggleEdit = useCallback(() => {
    setEdit(!edit);
  }, [edit, setEdit]);

  const editText = edit ? "Cancel" : "Edit";
  const editVariant = edit ? "danger" : "primary";

  if (loading) {
    return (
      <StandardPageContainer>
        <Spinner animation="border" />
      </StandardPageContainer>
    );
  }

  return (
    <StandardPageContainer>
      <Card>
        <Card.Header>
          <Row>
            <StyledHeaderCol>
              <h2>Form Period {id}</h2>
              <Button
                variant={editVariant}
                onClick={toggleEdit}
              >
                {editText}
              </Button>
            </StyledHeaderCol>
          </Row>
        </Card.Header>
        <Card.Body>
          <DataRow
            label="Form Type"
            value={data?.formPeriod?.formType?.shortName || ""}
          />
          <DataRow
            label="Start Date"
            valueType="date"
            value={data?.formPeriod?.periodStart || ""}
          />
          <DataRow
            label="Deadline"
            valueType="date"
            value={data?.formPeriod?.filingDeadline || ""}
          />
          <DataRow
            label="Filing Year"
            value={String(data?.formPeriod?.filingYear || "")}
          />
          <DataRow
            label="Time zone"
            value={data?.formPeriod?.timezone || ""}
          />
        </Card.Body>
      </Card>
      <FormToggle
        id={numberId}
        edit={edit}
        afterUpdate={closeEdit}
      />
    </StandardPageContainer>
  );
};

export default FormPeriod;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pt50pfUtils = void 0;
const lodash_1 = require("lodash");
const isFreeportLevelOneCategoryThree = (category) => {
    return (category.shipmentsCompleteYear !==
        undefined,
        category.outOfStateShipments !==
            undefined,
        category.inventoryJanFirst !== undefined);
};
const categories = [
    "categoryOne",
    "categoryTwo",
    "categoryThree",
    "categoryFour",
];
const getExemptionValue = (cost, exemptionPercentage) => {
    return (0, lodash_1.round)(cost * (exemptionPercentage / 100), 2);
};
// Gets the cost for level one category three but can be applied to any level
// one category safely.
const getLevelOneCost = (values) => {
    if (!values)
        return 0;
    if (isFreeportLevelOneCategoryThree(values)) {
        const { shipmentsCompleteYear, outOfStateShipments, inventoryJanFirst } = values;
        const percentage = (outOfStateShipments || 0) / (shipmentsCompleteYear || 0);
        const displayedPercentage = isNaN(percentage) ? 0 : percentage;
        const estimate = displayedPercentage * (inventoryJanFirst || 0);
        if (isNaN(estimate))
            return 0;
        return estimate;
    }
    return values.cost || 0;
};
// NOTE: This is going to end up not being used for now. Whitfield insists that
// taxpayers be required to enter these values manually. Something about
// various counties not always interpreting the math the same way...
// You have been informed.
const getFreeportLevelOneCategoryTotals = ({ freeportLevelOne, }) => {
    return categories.reduce((acc, category) => {
        const freeportLevelOneCategory = (freeportLevelOne === null || freeportLevelOne === void 0 ? void 0 : freeportLevelOne[category]) || {};
        const { exemptionPercentage } = freeportLevelOneCategory;
        const cost = getLevelOneCost(freeportLevelOneCategory);
        if (!cost)
            return acc;
        return {
            costTotal: (0, lodash_1.round)(acc.costTotal + (cost || 0), 2),
            exemptionValueTotal: (0, lodash_1.round)(acc.exemptionValueTotal +
                getExemptionValue(cost || 0, exemptionPercentage || 0), 2),
        };
    }, {
        costTotal: 0,
        exemptionValueTotal: 0,
    });
};
const getFreeportLevelTwoExemptionAmount = ({ freeportLevelTwo, }) => {
    const { totalCostOfAllMerchandise, appropriateExemption } = freeportLevelTwo || {};
    return (0, lodash_1.round)(getExemptionValue(totalCostOfAllMerchandise || 0, appropriateExemption || 0), 2);
};
const getFreeportGrandTotalExemptionValue = ({ freeportLevelOne, freeportLevelTwo, }) => {
    const { exemptionAmount } = freeportLevelOne || {};
    const exemptionValueTotalLevelTwo = getFreeportLevelTwoExemptionAmount({
        freeportLevelTwo,
    });
    return (0, lodash_1.round)((exemptionAmount || 0) + exemptionValueTotalLevelTwo, 2);
};
const getFreeportGrandTotalInventory = (opts) => {
    const { totalAllInventory, freeportLevelOne, freeportLevelTwo } = opts;
    const exemptionTotal = getFreeportGrandTotalExemptionValue(opts);
    return (totalAllInventory || 0) - exemptionTotal;
};
exports.pt50pfUtils = {
    getLevelOneCost,
    getExemptionValue,
    getFreeportGrandTotalInventory,
    getFreeportGrandTotalExemptionValue,
    getFreeportLevelOneCategoryTotals,
    getFreeportLevelTwoExemptionAmount,
};

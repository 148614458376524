import { gql, useMutation } from "@apollo/client";
import { GET_FORM_PERIOD } from "./useFormPeriod";
import { GET_JURISDICTION } from "./useJurisdiction";

const CREATE_FORM_PERIOD = gql`
  mutation CreateFormPeriod($input: FormPeriodCreateInput!) {
    createFormPeriod(input: $input) {
      id
    }
  }
`;

const useCreateFormPeriod = () => {
  return useMutation(CREATE_FORM_PERIOD, {
    refetchQueries: [
      GET_JURISDICTION,
      GET_FORM_PERIOD,
    ],
  });
};

export default useCreateFormPeriod;

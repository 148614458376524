import { gql, useMutation } from "@apollo/client";
import { GET_FORM_PERIOD } from "./useFormPeriod";
import { GET_JURISDICTION } from "./useJurisdiction";

const UPDATE_FORM_PERIOD = gql`
  mutation UpdateFormPeriod($id: Int!, $input: FormPeriodUpdateInput!) {
    updateFormPeriod(id: $id, input: $input)
  }
`;

const useUpdateFormPeriod = () => {
  return useMutation(UPDATE_FORM_PERIOD, {
    refetchQueries: [
      GET_JURISDICTION,
      GET_FORM_PERIOD,
    ],
  });
};

export default useUpdateFormPeriod;

import React, { FC, useCallback, useMemo } from "react";
import { Formik, FormikHelpers, useField } from "formik";

import FormPeriodForm from ".";
import { Card, Col, Row } from "react-bootstrap";
import TextField from "../../../shared/TextField";
import styled from "styled-components";
import FormSelect, { SelectFieldSetter } from "../../../shared/FormSelect";
import useJurisdictionFormTypes from "../../../../hooks/useJurisdictionFormTypes";
import useCreateFormPeriod from "../../../../hooks/useCreateFormPeriod";
import { useAppToastContext } from "@taxscribe/ui";

export interface NewFormPeriodProps {
  jurisdictionId: number;
}

const StyledCard = styled(Card)`
  margin-top: 1rem;
`;

const emptyOptions = [{ label: "Select a form type", value: 0 }];

const setField: SelectFieldSetter = (e) => {
  return Number(e.target.value);
};

const NewFormPeriodFields: FC = () => {
  const [jurIdField] = useField("jurisdictionId");
  const { data } = useJurisdictionFormTypes(jurIdField.value);
  const options = useMemo(() => {
    const formTypes = data?.jurisdiction?.formTypes || [];
    const formOptions = formTypes.map((formType) => ({
      label: formType.shortName,
      value: Number(formType.id),
    }));

    return [...emptyOptions, ...formOptions];
  }, [data]);

  return (
    <Row>
      <Col md={4}>
        <TextField
          fieldType="number"
          label="Filing Year"
          fieldName="filingYear"
        />
      </Col>
      <Col md={4}>
        <FormSelect
          options={options}
          label="Form Type"
          setField={setField}
          fieldName="formTypeId"
        />
      </Col>
    </Row>
  );
};

const NewFormPeriod: FC<NewFormPeriodProps> = ({ jurisdictionId }) => {
  const [create] = useCreateFormPeriod();
  const { addToastMessage } = useAppToastContext();
  const initialValues = useMemo(() => {
    return {
      jurisdictionId,
      formTypeId: 0,
      timezone: "US/Eastern",
      periodStart: new Date(),
      filingDeadline: new Date(),
      filingYear: new Date().getFullYear(),
    };
  }, [jurisdictionId]);

  const handleSubmit = useCallback(
    async (
      formData: typeof initialValues,
      helpers: FormikHelpers<typeof initialValues>,
    ) => {
      await create({ variables: { input: formData } }).then(() => {
        helpers?.resetForm();
        addToastMessage({
          type: "success",
          header: "Success",
          body: "Form Period Created",
        });
      }).catch((err) => {
        console.error(err);
        addToastMessage({
          type: "error",
          header: "Error",
          body: "Error creating form period",
        });
      });
    },
    [create, addToastMessage],
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <StyledCard>
        <Card.Header>
          <h3> New Form Period </h3>
        </Card.Header>
        <Card.Body>
          <NewFormPeriodFields />
          <FormPeriodForm />
        </Card.Body>
      </StyledCard>
    </Formik>
  );
};

export default NewFormPeriod;

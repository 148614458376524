import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { JurisdictionWithFormPeriodsAndFormType } from "../types/jurisdictions";

export const GET_JURISDICTION = gql`
  query GetJurisdiction($id: Int!) {
    jurisdiction(id: $id) {
      id
      name
      stateAbbr
      formPeriods {
        id
        formTypeId
        jurisdictionId
        filingYear
        periodStart
        filingDeadline
        createdAt
        updatedAt
        timezone
        formType {
          id
          longName
          shortName
          stateAbbr
          digitalOnly
        }
      }
    }
  }
`;

const useJurisdiction = (id?: number) => {
  const [query, queryResult] = useLazyQuery<{
    jurisdiction: JurisdictionWithFormPeriodsAndFormType;
  }>(GET_JURISDICTION);

  useEffect(() => {
    if (!id) return;
    query({ variables: { id } });
  }, [query, id]);
  return queryResult;
};

export default useJurisdiction;

import React, { FC } from "react";
import { useField } from "formik";
import { Form } from "react-bootstrap";

interface TextFieldProps {
  label: string;
  fieldName: string;
  fieldType?: string;
}

const TextField: FC<TextFieldProps> = ({
  label,
  fieldName,
  fieldType = "text",
}) => {
  const [field] = useField(fieldName);
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control type={fieldType} {...field}/>
    </Form.Group>
  );
};

export default TextField;

import React, { FC, useCallback } from "react";
import ReactSelect from "react-select";
import { Col, Container, Row } from "react-bootstrap";

import useFilterSetContext from "../../../hooks/useFilterSetContext";
import { FilterOptionTypes, NullableFilterOption } from "./FilterSetWrapper";

export interface FilterDefinition {
  label: string;
  options: { value: FilterOptionTypes; label: string }[];
}

interface FilterProps extends FilterDefinition {
  selected: NullableFilterOption | null;
  onChange: (key: string, opt: NullableFilterOption) => void;
}

const Filter: FC<FilterProps> = ({ label, options, selected, onChange }) => {
  const handleChange = useCallback(
    (option: NullableFilterOption) => {
      onChange(label, option);
    },
    [onChange, label],
  );

  return (
    <Col>
      <span>{label}</span>
      <ReactSelect
        value={selected}
        options={options}
        onChange={handleChange}
      />
    </Col>
  );
};

export interface FilterSetProps {
  filters: FilterDefinition[];
}

const FilterSet: FC<FilterSetProps> = ({
  filters,
}) => {
  const { rawFilters, setRawFilters } = useFilterSetContext();
  const onFilterChange = useCallback(
    (key: string, opt: NullableFilterOption) => {
      setRawFilters({
        ...rawFilters,
        [key]: opt,
      });
    },
    [rawFilters, setRawFilters],
  );

  return (
    <Container>
      <Row>
        {filters.map((filterProps) => {
          const selection = rawFilters[filterProps.label];
          return (
            <Filter
              selected={selection}
              key={filterProps.label}
              onChange={onFilterChange}
              {...filterProps}
            />
          );
        })}
      </Row>
    </Container>
  );
};

export default FilterSet;

import { uniqBy } from "lodash";
import React, { FC, useMemo } from "react";

import { State } from "../../../types/states";
import useStates from "../../../hooks/useStates";
import FilterSet, { FilterDefinition } from "../../shared/FilterSet";
import { FilterOptionTypes } from "../../shared/FilterSet/FilterSetWrapper";

interface CreateFilterOptsArgs {
  key: keyof State;
  label: string;
  uniuqe?: boolean;
  convert?: (val: any) => FilterOptionTypes;
  states?: State[];
}

const createFilterOpts = ({
  key,
  label,
  uniuqe,
  states,
  convert = (val) => val,
}: CreateFilterOptsArgs): FilterDefinition | null => {
  if (!states) return null;
  const getOpts = (state: State) => {
    return {
      label: String(state[key]),
      value: convert(state[key]),
    };
  };

  const opts = states.map(getOpts);
  const uniqueOpts = uniuqe ? uniqBy(opts, "value") : opts;

  return {
    label,
    options: [
      {
        label: "All",
        value: null,
      },
      ...uniqueOpts,
    ],
  };
};

const optMap: CreateFilterOptsArgs[] = [
  {
    key: "abbr",
    label: "States",
    uniuqe: true,
  },
];

const JurisdictionFilters: FC = () => {
  const { data } = useStates();
  const filterOptions = useMemo(() => {
    return optMap
      .map((opt) =>
        createFilterOpts({ ...opt, states: data?.states }),
      )
      .filter(Boolean) as FilterDefinition[];
  }, [data]) as FilterDefinition[];

  return <FilterSet filters={filterOptions} />;
};

export default JurisdictionFilters;

import React, { FC, useMemo } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { Button, Col, Row } from "react-bootstrap";

import useTimezones from "../../../../hooks/useTimezones";
import { Timezone } from "../../../../types/timezones";
import FormDatePicker from "../../../shared/FormDatepicker";
import FormSelect from "../../../shared/FormSelect";

const RowWithPadding = styled(Row)`
  padding-top: 0.5rem;
`;

const emptyTimezones: Timezone[] = [];

const FormPeriodForm: FC = () => {
  const { data } = useTimezones();
  const { submitForm } = useFormikContext();
  const timezones = data?.timezones || emptyTimezones;
  const timezoneOptions = useMemo(
    () => {
      const opts = timezones.map((timezone) => ({
        label: timezone.name,
        value: timezone.name,
      }));

      return opts.sort((a, b) => a.label.localeCompare(b.label));
    },
    [timezones],
  );

  return (
    <>
      <RowWithPadding>
        <Col md={4}>
          <FormDatePicker
            label="Start Date"
            fieldName="periodStart"
          />
        </Col>
        <Col md={4}>
          <FormDatePicker
            label="Deadline"
            fieldName="filingDeadline"
          />
        </Col>
        <Col md={4}>
          <FormSelect
            label="Timezone"
            fieldName="timezone"
            options={timezoneOptions}
          />
        </Col>
      </RowWithPadding>
      <RowWithPadding>
        <Col>
          <Button onClick={submitForm}>Save</Button>
        </Col>
      </RowWithPadding>
    </>
  );
};

export default FormPeriodForm;

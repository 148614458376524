import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

const GET_JURISDICTION_FORM_TYPES = gql`
  query GetJurisdictionFormTypes($id: Int!) {
    jurisdiction(id: $id) {
      id
      formTypes {
        id
        shortName
        longName
        digitalOnly
        stateAbbr
      }
    }
  }
`;

export interface UseJurisdictionFormTypesResults {
  jurisdiction: {
    id: number;
    formTypes: {
      id: number;
      shortName: string;
      longName: string;
      digitalOnly: boolean;
      stateAbbr: string;
    }[];
  };
}

const useJurisdictionFormTypes = (jurisdictionId?: number) => {
  const [query, queryResults] = useLazyQuery<
    UseJurisdictionFormTypesResults
  >(
    GET_JURISDICTION_FORM_TYPES,
  );

  useEffect(() => {
    if (jurisdictionId) {
      query({
        variables: {
          id: jurisdictionId,
        },
      });
    }
  }, [query, jurisdictionId]);

  return queryResults;
};

export default useJurisdictionFormTypes;

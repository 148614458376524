import { useField } from "formik";
import React, { ChangeEvent, FC, useCallback } from "react";
import { Form } from "react-bootstrap";

export type SelectFieldSetter = (
  event: ChangeEvent<HTMLSelectElement>,
) => number | string;

export interface FormSelectProps {
  label: string;
  fieldName: string;
  setField?: SelectFieldSetter;
  options: { value: string | number; label: string }[];
}

const FormSelect: FC<FormSelectProps> = ({
  label,
  fieldName,
  setField,
  options,
}) => {
  // eslint-disable-next-line
  const [field, _, fieldHelpers] = useField(fieldName);
  const onChange = field.onChange;
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (setField) {
        fieldHelpers.setValue(setField(e));
        return;
      }

      onChange(e);
    },
    [setField, fieldHelpers, onChange],
  );

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        {...field}
        onChange={handleChange}
      >
        {options.map((opt) => {
          return (
            <option
              key={opt.label}
              value={opt.value}
            >
              {opt.label}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};

export default FormSelect;

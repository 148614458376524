import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { FormPeriodWithFormType } from "../types/formPeriods";

export const GET_FORM_PERIOD = gql`
  query GetFormPeriod($id: Int!) {
    formPeriod(id: $id) {
      id
      formTypeId
      jurisdictionId
      filingYear
      periodStart
      filingDeadline
      createdAt
      updatedAt
      timezone
      formType {
        id
        longName
        shortName
        stateAbbr
        digitalOnly
      }
    }
  }
`;

const useFormPeriod = (id?: number) => {
  const [query, queryResult] = useLazyQuery<{
    formPeriod: FormPeriodWithFormType;
  }>(GET_FORM_PERIOD);

  useEffect(() => {
    if (!id) return;
    query({ variables: { id } });
  }, [query, id]);
  return queryResult;
};

export default useFormPeriod;

import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FormPeriodWithFormType } from "../../../../types/formPeriods";
import useTimezoneDateTimes from "../../../../hooks/useTimezoneDateTimes";

interface FormPeriodRowProps {
  formPeriod: FormPeriodWithFormType;
}

const SplitRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FormPeriodRow: FC<FormPeriodRowProps> = ({ formPeriod }) => {
  const { id, filingYear, formType, filingDeadline, timezone, periodStart } =
    formPeriod;

  const {
    midnightStartOfDay: periodStartDateTime,
    midnightStartOfDayLocal: localPeriodStartDateTime,
  } = useTimezoneDateTimes({
    timezone,
    dateTime: periodStart,
  });

  const {
    midnightEndOfDay: filingDeadlineDateTime,
    midnightEndOfDayLocal: localFilingDeadlineDateTime,
  } = useTimezoneDateTimes({
    timezone,
    dateTime: filingDeadline,
  });
  const { shortName, longName } = formType;

  return (
    <tr>
      <td>
        <Link to={`/form-periods/${id}`}>{id}</Link>
      </td>
      <td>{filingYear}</td>
      <td>{shortName}</td>
      <td>{longName}</td>
      <td>
        <SplitRow>
          <span>{periodStartDateTime.toLocaleString()}</span>
          <span>{localPeriodStartDateTime.toISOTime()}</span>
        </SplitRow>
      </td>
      <td>
        <SplitRow>
          <span>{filingDeadlineDateTime.toLocaleString()}</span>
          <span>{localFilingDeadlineDateTime.toISOTime()}</span>
        </SplitRow>
      </td>
      <td>{timezone}</td>
    </tr>
  );
};

export default FormPeriodRow;

import { FC, useEffect } from "react";
import { useGoogleContext } from "@reasongcp/react-fire-sub";

const ForceSignIn: FC = () => {
  const { loaded, user, googleSignIn } = useGoogleContext();

  useEffect(() => {
    if (loaded && !user) {
      googleSignIn();
    }
  }, [loaded, user, googleSignIn]);

  return null;
};

export default ForceSignIn;

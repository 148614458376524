import React, { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { BreadcrumbItem } from "react-bootstrap";

const BreadcrumbLink: FC<LinkProps> = ({ children, ...linkProps }) => {
  return (
    <BreadcrumbItem
      linkAs={Link}
      linkProps={linkProps}
    >
      {children}
    </BreadcrumbItem>
  );
};

export default BreadcrumbLink;

import { DateTime } from "luxon";
import { useMemo } from "react";

export interface UseTimezoneDateTimeArgs {
  timezone: string;
  dateTime: string | Date | number;
}

const getDateTime = ({ dateTime }: UseTimezoneDateTimeArgs): DateTime => {
  if (!isNaN(Number(dateTime))) {
    return DateTime.fromMillis(Number(dateTime));
  }

  if (typeof dateTime === "string") {
    return DateTime.fromISO(dateTime);
  }

  return DateTime.fromJSDate(dateTime as Date);
};

/*
 * Accepts ISO strings, time in milliseconds, or Date objects
 */
const useTimezoneDateTimes = ({
  timezone,
  dateTime,
}: UseTimezoneDateTimeArgs) => {
  return useMemo(() => {
    const dt = getDateTime({ timezone, dateTime });
    const luxonDate = dt
      .setZone(timezone)
      .plus({ days: 1 })
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const followingMidnight = luxonDate.plus({ days: 1 });
    return {
      midnightStartOfDay: luxonDate,
      midnightStartOfDayLocal: luxonDate.toLocal(),
      midnightEndOfDay: followingMidnight,
      midnightEndOfDayLocal: followingMidnight.toLocal(),
    };
  }, [dateTime, timezone]);
};

export default useTimezoneDateTimes;

import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { Jurisdiction } from "../types/jurisdictions";

// NOTE: Do not add any subqueries to this query as that could get out of
// control very quickly.
const GET_JURISDICTIONS = gql`
  query GetJurisdictions(
    $ids: [Int]
    $names: [String]
    $stateAbbrs: [String]
    $searchTerm: String
    $limit: Int
  ) {
    jurisdictions(
      ids: $ids
      names: $names
      stateAbbrs: $stateAbbrs
      searchTerm: $searchTerm
      limit: $limit
    ) {
      id
      name
      stateAbbr
    }
  }
`;

export interface JurisdictionVariables {
  ids?: number[];
  names?: string[];
  stateAbbrs?: string[];
  searchTerm?: string;
  limit?: number;
}

const useJurisdictions = (variables?: JurisdictionVariables) => {
  const [query, results] = useLazyQuery<{ jurisdictions: Jurisdiction[] }>(
    GET_JURISDICTIONS,
    { variables },
  );

  useEffect(() => {
    const searchFunc = window.setTimeout(() => {
      query({ variables });
    }, 500);

    return () => {
      window.clearTimeout(searchFunc);
    };
  }, [query, variables]);

  return results;
};

export default useJurisdictions;

import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faX } from "@fortawesome/free-solid-svg-icons";

import { StenoTemplate } from "../../../types/stenoTemplates";

const PresenceIndicator: FC<StenoTemplate> = ({ exists }) => {
  if (exists) {
    return (
      <span className="text-success">
        <FontAwesomeIcon icon={faCheckCircle} />
      </span>
    );
  }

  return (
    <span className="text-danger">
      <FontAwesomeIcon icon={faX} />
    </span>
  );
};

export default PresenceIndicator;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddressForOrganization = void 0;
const lodash_1 = require("lodash");
const getAddressForOrganization = ({ orgUuid, firestore, collection = "organizations", }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!firestore) {
        throw new Error("No firestore instance available");
    }
    if (!orgUuid) {
        throw new Error("No orgUuid provided");
    }
    const orgDoc = yield firestore.doc(`${collection}/${orgUuid}`).get();
    if (!orgDoc.exists) {
        throw new Error(`${collection}/${orgUuid} does not exist in Firestore`);
    }
    const data = orgDoc === null || orgDoc === void 0 ? void 0 : orgDoc.data();
    const companyName = (0, lodash_1.get)(data, "name", "");
    const addressLine1 = (0, lodash_1.get)(data, "address", "");
    const addressLine2 = (0, lodash_1.get)(data, "addressTwo");
    const city = (0, lodash_1.get)(data, "city", "");
    const provinceOrState = (0, lodash_1.get)(data, "state", "");
    const postalOrZip = (0, lodash_1.get)(data, "zipCode", "");
    if (!companyName ||
        !addressLine1 ||
        !city ||
        !provinceOrState ||
        !postalOrZip) {
        throw new Error(`Unable to create complete address for ${collection}{/${orgUuid}`);
    }
    return {
        city,
        addressLine1,
        addressLine2,
        postalOrZip,
        provinceOrState,
        countryCode: "US",
        companyName,
    };
});
exports.getAddressForOrganization = getAddressForOrganization;

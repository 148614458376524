import React, { FC } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { GoogleAuthBoundary, GoogleContextProvider } from "@reasongcp/react-fire-sub";

import ApolloAdminProvider from "./components/shared/ApolloProvider";
import AppRoutes from "./components/shared/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import AppNavbar from "./components/shared/AppNavBar";
import { AppToastProvider, AppToasts, toastComponents } from "@taxscribe/ui";
import AppBreadcrumbs from "./components/shared/AppBreadcrumbs";
import config from "./config";
import ForceSignIn from "./components/shared/ForceSignIn";

const App: FC = () => {
  return (
    <GoogleContextProvider config={config.googleConfig}>
      <AppToastProvider
        defaultTimeout={3000}
        ToastComponents={toastComponents}
      >
        <GoogleAuthBoundary PlaceHolder={ForceSignIn}>
          <ApolloAdminProvider>
            <BrowserRouter>
              <AppNavbar />
              <AppBreadcrumbs />
              <AppRoutes />
            </BrowserRouter>
          </ApolloAdminProvider>
          <AppToasts />
        </GoogleAuthBoundary>
      </AppToastProvider>
    </GoogleContextProvider>
  );
};

export default App;

import React, { FC } from "react";
import styled from "styled-components";
import { Container, Nav, Navbar } from "react-bootstrap";

import SignInOrOut from "./SingInOrOut";
import colors from "../../../constants/colors";
import { pages } from "../AppRoutes";
import { GoogleAuthBoundary } from "@reasongcp/react-fire-sub";

const StyledNavbar = styled(Navbar)`
  background-color: ${colors.tsBlueGray};
`;

const filteredPages = pages.filter(({ path }) => !path.match(":"));

const AppNavbar: FC = () => {
  return (
    <StyledNavbar expand="lg">
      <Container>
        <Navbar.Brand href="/">TaxScribe Admin</Navbar.Brand>
        <GoogleAuthBoundary>
          {filteredPages.map(({ path, title }) => (
            <Nav.Link key={path} href={path}>
              {title}
            </Nav.Link>
          ))}
        </GoogleAuthBoundary>
        <SignInOrOut />
      </Container>
    </StyledNavbar>
  );
};

export default AppNavbar;

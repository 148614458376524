import React, { FC, useCallback, useMemo } from "react";
import { Formik } from "formik";

import useFormPeriod from "../../../../hooks/useFormPeriod";
import useUpdateFormPeriod from "../../../../hooks/useUpdateFormPeriod";
import FormPeriodForm from ".";
import styled from "styled-components";
import { Card } from "react-bootstrap";
import { useAppToastContext } from "@taxscribe/ui";

const CardWithMargin = styled(Card)`
  margin-top: 1rem;
`;

interface UpdateFormPeriodProps {
  id: number;
  afterUpdate?: () => void;
}

const UpdateFormPeriod: FC<UpdateFormPeriodProps> = ({ id, afterUpdate }) => {
  const { addToastMessage } = useAppToastContext();
  const { data, loading } = useFormPeriod(id);
  const [mutation] = useUpdateFormPeriod();
  const handleSubmit = useCallback(
    (data: any) => {
      mutation({
        variables: {
          id: Number(id),
          input: data,
        },
      }).then(() => {
        addToastMessage({
          type: "success",
          header: "Success",
          body: "Form period updated successfully.",
        });
        afterUpdate && afterUpdate();
      }).catch((err) => {
        console.error(err);
        addToastMessage({
          type: "error",
          header: "Error",
          body: "There was an error updating the form period.",
        });
      });
    },
    [mutation, id, afterUpdate, addToastMessage],
  );

  const filteredInitialValue = useMemo(() => {
    return {
      periodStart: data?.formPeriod?.periodStart || "",
      filingDeadline: data?.formPeriod?.filingDeadline || "",
      timezone: data?.formPeriod?.timezone || "",
    };
  }, [data]);

  if (loading) return null;
  if (!data) return null;

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={filteredInitialValue}
    >
      <CardWithMargin>
        <Card.Header>
          <h3>Edit Form Period</h3>
        </Card.Header>
        <Card.Body>
          <FormPeriodForm />
        </Card.Body>
      </CardWithMargin>
    </Formik>
  );
};

export default UpdateFormPeriod;

import { startCase } from "lodash";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

interface UseAppBreadcrumbsArgs {
  base: {
    label: string;
    path: string;
  };
}

const useAppBreadcrumbs = ({ base }: UseAppBreadcrumbsArgs) => {
  const { pathname } = useLocation();
  return useMemo(() => {
    const pathArray = pathname.split("/").filter((p) => p);
    const crumbs = pathArray.reduce(
      (acc, path) => {
        const currentPath = acc
          .map((c) => c.path)
          .filter((c) => c !== "/")
          .join("/");

        return [
          ...acc,
          {
            label: startCase(path),
            path: [currentPath, path].join("/"),
          },
        ];
      },
      [base],
    );

    return crumbs;
  }, [pathname, base]);
};

export default useAppBreadcrumbs;

import React, { createContext, FC, PropsWithChildren, useMemo, useState } from "react";

export type FilterOptionTypes = string | number | null;
export interface FilterOption {
  label: string;
  value: FilterOptionTypes;
}
export type NullableFilterOption = FilterOption | null;
export interface FiltersState {
  [key: string]: NullableFilterOption;
}
export interface FilterSetContextState {
  filters: Record<string, FilterOptionTypes>;
  rawFilters: FiltersState;
  setRawFilters: (state: FiltersState) => void;
}

export const FilterSetContext = createContext<FilterSetContextState>({
  filters: {},
  rawFilters: {},
  setRawFilters: () => {},
});

const useFilterState = (defaultState: FiltersState = {}) => {
  const [rawFilters, setRawFilters] = useState(defaultState);

  return useMemo(() => {
    const filters = Object.entries(rawFilters).reduce((acc, [key, opt]) => {
      if (opt === null) return acc;
      return { ...acc, [key]: opt.value };
    }, {});
    return {
      filters,
      rawFilters,
      setRawFilters,
    };
  }, [rawFilters, setRawFilters]);
};

const FilterSetWrapper: FC<PropsWithChildren> = ({ children }) => {
  const filterState = useFilterState();
  return (
    <FilterSetContext.Provider value={filterState}>
      {children}
    </FilterSetContext.Provider>
  );
};

export default FilterSetWrapper;
